import { AdapterNoopControllerFn } from '../../common/controller/editor-adapter';
import { createPostPageApi } from './create-post-page-api';

export const createOldPostPageController: AdapterNoopControllerFn = ({
  appParams,
  wixCodeApi,
  flowAPI,
}) => {
  return {
    pageReady: () => {},
    exports: () => createPostPageApi(appParams.instance, wixCodeApi, flowAPI),
  };
};
