import { chain } from 'lodash';
import {
  SANTA_MEMBERS_APP_ID,
  MEMBERS_ABOUT_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createAction } from '@wix/communities-blog-client-common';
import { getIsMemberAreaInstalled } from '../communities-context/communities-context-selectors';
import { getProfileUrls } from './profile-urls-selectors';

export const SET_PROFILE_URLS = 'profileUrls/SET';

export const setProfileUrls = createAction(SET_PROFILE_URLS);

export const fetchProfileUrls =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    const state = getState();
    if (!getIsMemberAreaInstalled(state)) {
      return;
    }
    const fetchedProfileUrls = getProfileUrls(state);
    const siteMembersToFetch = chain(state.posts)
      .values()
      .filter((post) => post.owner?.privacyStatus === 'PUBLIC')
      .map((post) => post.owner.siteMemberId)
      .uniq()
      .filter((siteMemberId) => !fetchedProfileUrls[siteMemberId])
      .value();

    if (siteMembersToFetch.length < 1) {
      return;
    }

    try {
      const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
      const promises = siteMembersToFetch.map((siteMemberId) =>
        api
          .getSectionUrl({
            appDefinitionId: MEMBERS_ABOUT_APP_ID,
            sectionId: 'about',
            memberId: siteMemberId,
          })
          .then((url) => [siteMemberId, url]),
      );

      const urlsMap = Object.fromEntries(await Promise.all(promises));

      dispatch(setProfileUrls(urlsMap));
    } catch (error) {
      console.log(error);
    }
  };
