import {
  chain,
  cloneDeep,
  findKey,
  isEmpty,
  map,
  reduce,
  omitBy,
  some,
  without,
  includes,
} from 'lodash';
import {
  resolveId,
  getLastPage,
  ALL_ENTITY_TYPES,
} from '@wix/communities-blog-client-common';

export const INITIAL_STATE = reduce(
  ALL_ENTITY_TYPES,
  (result, entityType) => ({
    ...result,
    [entityType]: {
      entitiesByPage: {},
    },
  }),
  {},
);

export const withEntities = ({
  state,
  entities,
  page,
  pageSize,
  entityCount,
  entityType,
  cursor,
}) => {
  const entityState = state[entityType] || {};
  const { entitiesByPage } = entityState;

  if (cursor === 'undefined') {
    cursor = null;
  }

  return {
    [entityType]: {
      ...entityState,
      pageSize,
      entitiesByPage: {
        ...entitiesByPage,
        [page]: map(entities, resolveId),
      },
      entityCount,
      cursor,
    },
  };
};

const removeOverflow = (pageIds, pageSize) =>
  pageIds && pageIds.length > pageSize && pageIds.pop();
const nextPageIsLoaded = (entitiesByPage, page, totalPages) =>
  entitiesByPage[page + 1] || page === totalPages;

const shiftEntityIdsRight = ({
  entitiesByPage: entities,
  pageSize,
  fromPage = 1,
  totalPages,
}) => {
  let page = fromPage;
  const entitiesByPage = cloneDeep(entities);
  let overflow = removeOverflow(entitiesByPage[page], pageSize);

  while (overflow && nextPageIsLoaded(entitiesByPage, page, totalPages)) {
    page++;
    const entityIds = entitiesByPage[page] || [];
    entityIds.unshift(overflow);
    overflow = removeOverflow(entityIds, pageSize);
    entitiesByPage[page] = entityIds;
  }

  return entitiesByPage;
};

const appendedToPage = ({ entitiesByPage, entityId, page = 1 }) => ({
  ...entitiesByPage,
  [page]: [entityId, ...(entitiesByPage[page] || [])],
});

const totalPages = (entityCount, entityType) =>
  getLastPage(entityCount, entityType) || 1;

export const withEntityInHead = ({ state, entityType, entityId }) => {
  const entityState = state[entityType];
  if (entityState) {
    const { pageSize, entitiesByPage } = entityState;
    const entityCount = entityState.entityCount + 1;

    return {
      [entityType]: {
        ...entityState,
        entitiesByPage: shiftEntityIdsRight({
          entitiesByPage: appendedToPage({ entitiesByPage, entityId }),
          pageSize,
          totalPages: totalPages(entityCount, pageSize),
        }),
        entityCount,
      },
    };
  }
};

const lastPage = (entitiesByPage) =>
  chain(entitiesByPage).keys().sort().last().toNumber().value() || 1;

const prependedToPage = ({ entitiesByPage, entityId, page }) => ({
  ...entitiesByPage,
  [page]: [...(entitiesByPage[page] || []), entityId],
});

export const withEntityInTail = ({ state, entityType, entityId }) => {
  const entityState = state[entityType];
  if (entityState) {
    const { pageSize, entitiesByPage } = entityState;
    const entityCount = entityState.entityCount + 1;
    const fromPage = lastPage(entitiesByPage);

    return {
      [entityType]: {
        ...entityState,
        entitiesByPage: shiftEntityIdsRight({
          entitiesByPage: prependedToPage({
            entitiesByPage,
            entityId,
            page: fromPage,
          }),
          pageSize,
          fromPage,
          totalPages: totalPages(entityCount, pageSize),
        }),
        entityCount,
      },
    };
  }
};

const borrow = (pageIds) => pageIds && pageIds.shift();

const shiftEntityIdsLeft = ({ entitiesByPage, entityId }) => {
  let page = Number(findKey(entitiesByPage, (ids) => includes(ids, entityId)));
  const appendedEntitiesByPage = {
    ...cloneDeep(entitiesByPage),
    [page]: without(entitiesByPage[page], entityId),
  };
  let deficit = borrow(appendedEntitiesByPage[page + 1]);

  while (deficit) {
    appendedEntitiesByPage[page].push(deficit);
    page++;
    deficit = borrow(appendedEntitiesByPage[page + 1]);
  }

  return {
    ...omitBy(appendedEntitiesByPage, isEmpty),
    ...(appendedEntitiesByPage[1] && { 1: appendedEntitiesByPage[1] }),
  };
};

const containsId = (entitiesByPage, entityId) =>
  some(entitiesByPage, (ids) => includes(ids, entityId));

export const withoutEntity = ({ state, entityTypes, entityId }) =>
  reduce(
    entityTypes,
    (result, entityType) => {
      const entityState = state[entityType];
      if (entityState) {
        const { entitiesByPage, entityCount } = entityState;
        const containsEntity = containsId(entitiesByPage, entityId);
        result[entityType] = {
          ...entityState,
          entitiesByPage: containsEntity
            ? shiftEntityIdsLeft({ entitiesByPage, entityId })
            : entitiesByPage,
          entityCount: containsEntity ? entityCount - 1 : entityCount,
        };
      }
      return result;
    },
    {},
  );

export const withCurrentPage = ({ state, entityType, currentPage }) => ({
  [entityType]: {
    ...state[entityType],
    currentPage: currentPage || 1,
  },
});
