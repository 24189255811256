import { IHttpClient } from '@wix/http-client';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_ENABLE_RATINGS_IN_VIEWER } from '@wix/communities-blog-experiments';
import { getIsPostRatingEnabled } from '../../../common/selectors/app-settings-selectors';
import { updatePostsRatings } from '../../../common/store/post-ratings/post-ratings-actions';
import { AppStore, ControllerConfig } from '../../../common/types';

type Params = {
  controllerConfig: ControllerConfig;
  httpClient: IHttpClient;
  store: AppStore;
};

export async function initWixCommentsController({
  controllerConfig,
  store,
  httpClient,
}: Params) {
  const initializeCommentsController = (
    await import(
      /* webpackChunkName: "wix-comments-controller" */ './wix-comments-controller'
    )
  ).default;
  const withRatings = (() => {
    const state = store.getState();
    return (
      getIsPostRatingEnabled(state) &&
      isExperimentEnabled(state, EXPERIMENT_ENABLE_RATINGS_IN_VIEWER)
    );
  })();

  const wixCommentsApi = await initializeCommentsController({
    controllerConfig,
    httpClient,
  });

  if (withRatings) {
    wixCommentsApi.watch.ratings.onChange((state) => {
      store.dispatch(updatePostsRatings(state));
    });
  }
  return wixCommentsApi;
}
