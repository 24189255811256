import { I$W } from '@wix/yoshi-flow-editor';
import { WIDGET_ROLES } from '@wix/communities-blog-client-common';
import { RelatedPostsApi } from '../../related-posts-widget/controller/related-posts-api';

type CommnentsApi = {
  setResourceId: (resourceId: string) => Promise<void>;
};

export type ConnectionsByRole = {
  [WIDGET_ROLES.COMMENTS]: CommnentsApi[];
  [WIDGET_ROLES.RELATED_POSTS]: RelatedPostsApi[];
};

export function getConnections($w: I$W): ConnectionsByRole {
  if (typeof $w !== 'function') {
    return {
      [WIDGET_ROLES.COMMENTS]: [],
      [WIDGET_ROLES.RELATED_POSTS]: [],
    };
  }
  const relatedPosts = $w(`@${WIDGET_ROLES.RELATED_POSTS}`) ?? [];
  const comments = $w(`@${WIDGET_ROLES.COMMENTS}`) ?? [];

  return {
    [WIDGET_ROLES.COMMENTS]: comments,
    [WIDGET_ROLES.RELATED_POSTS]: relatedPosts,
  };
}

export async function initCommentsWidget(
  connections: ConnectionsByRole,
  postId: string,
) {
  await Promise.all(
    connections[WIDGET_ROLES.COMMENTS].map(async (connection) => {
      if (connection.setResourceId) {
        await connection.setResourceId(postId);
      }
    }),
  );
}

export async function initRelatedPostsWidget(
  connections: ConnectionsByRole,
  posts: unknown[],
) {
  await Promise.all(
    connections[WIDGET_ROLES.RELATED_POSTS].map(async (connection) => {
      if (connection.setPosts) {
        await connection.setPosts(posts);
      }
    }),
  );
}
