export {
  initializeStoreBaseData,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

import { bindActionCreators } from 'redux';
import { CommentsControllerApi } from '@wix/comments-ooi-client/dist/types/controller/controller-public-types';
import { tagClicked } from '../../common/actions/tag-clicked';
import {
  initializeActions as initializeCommonActions,
  initializePromisifiedActions as initializeCommonPromisifiedActions,
} from '../../common/controller/init-actions';
import { setIsLoaded } from '../../common/store/is-loaded/is-loaded-actions';
import {
  cleanPostEditorState,
  setIsEditorBusy,
  updatePostEditorPost,
} from '../../common/store/post-editor/post-editor-actions';
import { AppStore, ControllerConfig, FlowAPI } from '../../common/types';
import { biActiveTabChanged, biPostScrolled } from '../actions/bi';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';
import { postPageResized } from '../actions/post-page-resize';
import { emitClosePost, emitOpenPost } from '../actions/post-socket';
import { initWixCommentsController } from './wix-comments';

type InitializeActionsParams = {
  controllerConfig: ControllerConfig;
  store: AppStore;
  flowAPI: FlowAPI;
};

export function initializeActions({
  controllerConfig,
  store,
  flowAPI,
}: InitializeActionsParams) {
  let commentsControllerApi: CommentsControllerApi | undefined;

  return {
    initWixCommentsController: async () => {
      if (commentsControllerApi) {
        return;
      }

      commentsControllerApi = await initWixCommentsController({
        controllerConfig,
        store,
        httpClient: flowAPI.httpClient,
      });
    },
    ...initializeCommonActions({
      store,
      wixCodeApi: controllerConfig.wixCodeApi,
      fedopsLogger: flowAPI.fedops,
    }),
    ...bindActionCreators(
      {
        fetchRecentPosts,
        fetchRelatedPosts,
        setIsLoaded,
        cleanPostEditorState,
        updatePostEditorPost,
        tagClicked,
        postPageResized,
        biPostScrolled,
        biActiveTabChanged,
        emitOpenPost,
        emitClosePost,
        setIsEditorBusy,
      },
      store.dispatch,
    ),
  };
}

type InitializePromisifiedActionsParams = {
  store: AppStore;
};

export function initializePromisifiedActions({
  store,
}: InitializePromisifiedActionsParams) {
  return initializeCommonPromisifiedActions({ store });
}
