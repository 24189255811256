import type { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import { extend, merge, trimEnd, noop, get, isObject, pickBy } from 'lodash';
import { IHttpClient } from '@wix/http-client';
import { retryRequest, urijs } from '@wix/communities-blog-client-common';

type CustomHttpClient = {
  client: {
    request: (url: string, config?: AxiosRequestConfig) => AxiosPromise;
  };
  cancelTokenSource: IHttpClient['CancelToken']['source'];
  isCancel: IHttpClient['isCancel'];
};

type PerformanceTracker = {
  trackStart: (name: string) => string;
  trackEnd: (name: string) => string;
};

type CreateRequestParams = {
  baseUrl?: string;
  getInstance?: () => string | null | undefined;
  locale?: string;
  groupId?: string;
  cookie?: string;
  performanceTracker?: PerformanceTracker;
  siteRevision?: string;
  trackError?: (error: any) => void;
  logResponse?: (response: any) => void;
  petriOvr?: string;
  httpClient: CustomHttpClient;
  isSSR?: boolean;
};

type RequestConfig = AxiosRequestConfig & {
  parseHeaders?: boolean;
  retry?: number;
  instance?: string;
  headers?: Record<string, string>;
  baseUrl?: string;
  apiBaseUrl?: string;
  dismissHeaders?: boolean;
  dismissParams?: boolean;
};

export default function createRequest({
  baseUrl = '',
  getInstance = () => null,
  locale = 'en',
  groupId,
  cookie,
  performanceTracker = { trackStart: noop as any, trackEnd: noop as any },
  siteRevision,
  trackError = () => {},
  logResponse = () => {},
  petriOvr = '',
  httpClient,
  isSSR,
}: CreateRequestParams) {
  const defaultHeaders = pickBy(
    {
      cookie,
      'group-ip': groupId,
      'x-wix-site-revision': siteRevision,
    },
    (value): value is string => !!value,
  );

  const abortableFetch = (url: string, config: RequestConfig = {}) => {
    const signal = httpClient.cancelTokenSource();
    return {
      abort: () => signal.cancel('Fetch was aborted'),
      ready: httpClient.client.request(url, {
        ...config,
        cancelToken: signal.token,
      }),
    };
  };

  function request<T>(
    path: string,
    config: RequestConfig & { parseHeaders: true },
  ): Promise<{ body: T; headers: Record<string, string> }>;
  function request<T>(
    path: string,
    config?: RequestConfig & { parseHeaders?: boolean },
  ): Promise<T>;
  function request<T>(path: string, config: RequestConfig = {}): Promise<T> {
    const parseResponse = config?.parseHeaders
      ? (res: AxiosResponse) => ({
          body: res.data,
          headers: isSSR ? new Headers(res.headers) : res.headers,
        })
      : (res: AxiosResponse) => res.data;

    const retryCount = config.retry;
    const retryTimeout = 9000;
    const instance = config.instance || getInstance();
    delete config.instance;
    delete config.retry;
    delete config.parseHeaders;

    extend(config, {
      headers: {
        instance,
        Authorization: instance,
        locale,
        ...config.headers,
        ...defaultHeaders,
      },
      credentials: 'same-origin',
    });
    if (petriOvr) {
      config.params = {
        ...config.params,
        petri_ovr: petriOvr,
      };
    }
    if (config.dismissHeaders) {
      config.headers = undefined;
    }
    if (config.dismissParams) {
      config.params = undefined;
    }

    const marker = performanceTracker.trackStart(
      `${new Date().toISOString().slice(11)} ${config.method || 'GET'} ${path}`,
    );
    const url = `${trimEnd(
      config.baseUrl || config.apiBaseUrl || baseUrl,
      '/',
    )}${path}`;
    const start = () => abortableFetch(`${url}`, config);
    const parse = (response: AxiosResponse) =>
      Promise.resolve(response)
        .then(parseResponse)
        .then((r) => {
          performanceTracker.trackEnd(marker);
          return r;
        })
        .then(trackResponse(path, logResponse))
        .catch((error) => {
          const f = JSON.stringify;
          const status = get(error, 'status');
          const pathWithoutParams = path.split('?')[0];
          if (isErrorObject(error) && !error.stack) {
            // @ts-expect-error
            const parsedUrlForError = new urijs(url)
              .pathname()
              .split('/')
              .slice(2)
              .join('/');
            const message = `request failed: url=/${parsedUrlForError} status=${status} config=${f(
              config,
            )} error=${f(error)}`;
            error.stack = new Error(message).stack;
          }
          trackError(
            `request error: path=${pathWithoutParams}, status=${status}, error=${f(
              error,
            )}`,
          );
          return Promise.reject(error);
        });

    return retryRequest(
      start,
      url,
      retryCount,
      retryTimeout,
      httpClient.isCancel,
      undefined,
    ).then(parse);
  }

  const defineVerb =
    (method: 'POST' | 'PUT' | 'PATCH') =>
    (path: string, data: any, config: RequestConfig) =>
      request(
        path,
        merge({}, config, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        }),
      );

  request.post = defineVerb('POST');
  request.put = defineVerb('PUT');
  request.patch = defineVerb('PATCH');

  request.delete = (path: string, config?: AxiosRequestConfig) =>
    request(path, { ...config, method: 'DELETE' });

  return request;
}

const isErrorObject = (value: unknown): value is { stack?: string } => {
  return isObject(value);
};

const trackResponse =
  (
    path: string,
    logResponse: NonNullable<CreateRequestParams['logResponse']>,
  ) =>
  (response: any) => {
    logResponse([
      path,
      response && response.body ? response.body : response,
      response.headers || [],
    ]);

    return response;
  };
