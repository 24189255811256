import { get } from 'lodash';
import {
  setWixDataSuccess,
  createPerformanceTracker,
  getWixData,
} from '@wix/communities-blog-client-common';
import { AdapterControllerFn } from '../../common/controller/editor-adapter';
import {
  createControllerId,
  createLogger,
  isFrameless,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { AppStore } from '../../common/types';
import {
  fetchPostListPostsPromisified,
  fetchPostListPosts,
} from '../actions/fetch-initial-data';
import { RELATED_POSTS_WIDGET_BUNDLE_NAME } from '../constants/related-posts-widget';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createRelatedPostsController: AdapterControllerFn = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { fedopsLogger } = createMonitoring({ flowAPI });

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const bundleName = RELATED_POSTS_WIDGET_BUNDLE_NAME;

  const log = createLogger(isDebug, isProduction);

  log('createRelatedPostsController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    const controllerId = createControllerId();
    const perfTag = `${bundleName} ${controllerId}`;
    const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
    const pageReadyMarker = perf.trackStart(
      `${new Date().toISOString().slice(11)} pageReady`,
    );
    log('createRelatedPostsController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'relalated-posts.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      fedopsLogger,
      isSSR,
      language,
      bundleName: perfTag,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions(
      { store },
      { fetchPostListPostsPromisified },
    );
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          wixCodeApi,
          store,
          language,
          platformAPIs,
          config,
          bundleName,
          appParams,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createRelatedPostsController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    });

    if (
      (isEditor || isPreview) &&
      !isFrameless({ state: store.getState(), wixCodeApi })
    ) {
      listenToEditModeChange(store);
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return {
    pageReady: () =>
      isEditor
        ? handleProvisioning(
            appParams,
            fedopsLogger,
            wixCodeApi,
            setProps,
            pageReady,
            flowAPI,
          )
        : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams }, publicData }) => {
      if (!store) {
        return;
      }
      store.dispatch(setAppSettings({ style: styleParams }));

      const oldComponentData = getWixData(store.getState()) || {};
      const newComponentData = get(publicData, 'COMPONENT', {});

      if (
        oldComponentData.tagId !== newComponentData.tagId ||
        oldComponentData.categoryId !== newComponentData.categoryId
      ) {
        store.dispatch(setWixDataSuccess(newComponentData));
        store.dispatch(fetchPostListPosts());
      }
    },
  };
};
