import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createNoopController } from '../../external/common/controller/create-noop-controller';
import { editorAdapter } from '../../external/common/controller/editor-adapter';
import { createRelatedPostsController } from '../../external/related-posts-widget/controller';

const createController: CreateControllerFn = async ({
  appData,
  controllerConfig,
  flowAPI,
}) => {
  return editorAdapter({
    appData: appData || {},
    controllerConfig,
    flowAPI,
    createNoopController,
    createController: createRelatedPostsController,
  });
};

export default createController;
