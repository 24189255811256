import { CreateControllerFn, ExperimentsBag } from '@wix/yoshi-flow-editor';
import {
  EXPERIMENT_DISABLE_NOOP_CONTROLLER,
  EXPERIMENT_OOI_IN_EDITOR,
  EXPERIMENT_PROD_OOI_EDITOR,
} from '@wix/communities-blog-experiments';
import getEnvironment from '../services/get-environment';
import {
  AppData,
  AppParams,
  ControllerConfig,
  FlowAPI,
  WixCodeApi,
} from '../types';

const initializedControllers: Record<string, boolean> = {};

export type AdapterControllerFn = (
  controllerConfig: ControllerConfig,
  allCtrls: undefined,
  appData: AppData,
  flowAPI: FlowAPI,
) => ReturnType<CreateControllerFn>;

export type AdapterNoopControllerFn = (params: {
  wixCodeApi: WixCodeApi;
  appParams: AppParams;
  flowAPI: FlowAPI;
}) => ReturnType<CreateControllerFn>;

type AdapterParams = {
  appData: AppData;
  controllerConfig: ControllerConfig;
  flowAPI: FlowAPI;
  createController: AdapterControllerFn;
  createNoopController: AdapterNoopControllerFn;
};

export const editorAdapter = async ({
  appData,
  controllerConfig,
  flowAPI,
  createController,
  createNoopController,
}: AdapterParams) => {
  const { wixCodeApi, appParams, type, compId } = controllerConfig;
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const controllerId = `${type}-${compId}`;

  if (isEditor || isPreview) {
    const experiments = flowAPI.essentials.experiments.all();
    const isIFrameEnabled = getIsIFrameEnabled({
      isEditor,
      isPreview,
      experiments,
    });
    const isNoopControllerDisabled = getIsNoopControllerDisabled(experiments);
    const isWorker = getIsWorker();

    if (!isNoopControllerDisabled) {
      const _createNoopController = () =>
        createNoopController({ wixCodeApi, appParams, flowAPI });
      if (
        isIFrameEnabled &&
        (isWorker || initializedControllers[controllerId])
      ) {
        return _createNoopController();
      }

      if (
        !isIFrameEnabled &&
        isWorker &&
        initializedControllers[controllerId]
      ) {
        return _createNoopController();
      }
    }
  }

  initializedControllers[controllerId] = true;
  return createController(controllerConfig, undefined, appData, flowAPI);
};

function getIsIFrameEnabled({
  isEditor,
  isPreview,
  experiments,
}: {
  isEditor: boolean;
  isPreview: boolean;
  experiments?: ExperimentsBag;
}) {
  const isPreviewIFrame =
    isPreview && experiments?.[EXPERIMENT_PROD_OOI_EDITOR] === 'true';
  const isEditorIFrame =
    isEditor && experiments?.[EXPERIMENT_OOI_IN_EDITOR] !== 'true';

  return isPreviewIFrame || isEditorIFrame;
}

function getIsNoopControllerDisabled(experiments?: ExperimentsBag) {
  return experiments?.[EXPERIMENT_DISABLE_NOOP_CONTROLLER] === 'true';
}

function getIsWorker() {
  return (
    typeof WorkerGlobalScope !== 'undefined' &&
    self instanceof WorkerGlobalScope
  );
}
