// @ts-expect-error
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { PROVISION_SUCCESS } from '../constants/interactions';
import {
  AppParams,
  FedopsLogger,
  FlowAPI,
  SetProps,
  WixCodeApi,
} from '../types';
import createRequest from './create-request';
import { getHttpClientFromFlowAPI } from './get-http-client-from-flow-api';

type ProvisioningResponse = { status: number } | undefined;

/**
 * Handles internal provisioning
 *
 * Blog internal provisioning happens on server by listening to kafka event of blog provision.
 * Once that happens - blog is initializing data (categories, posts, etc).
 *
 * Provisioning handling done in such manner:
 *
 * 1. API `/blogs/this` is called to check provision status
 *    HTTP Status codes:
 *    200 - provisioning done
 *    404 - provisioning not started yet
 *    500 - Server error
 *
 * 2. If status 200 - call regular page ready to display blog
 *
 * 3. If status 404 -
 *    show loader ->
 *    poll provision status endpoint for success (retries multiple times with exponential backoff) ->
 *    call page ready ->
 *    hide loader
 *
 * 4. If status 500 - show error page
 */
export function handleProvisioning(
  appParams: AppParams,
  fedopsLogger: FedopsLogger,
  wixCodeApi: WixCodeApi,
  setProps: SetProps,
  pageReady: () => Promise<void>,
  flowAPI: FlowAPI,
) {
  const requestConfig = {
    baseUrl: appParams.baseUrls.apiBaseUrlClient,
    getInstance: () => wixCodeApi.site.getAppToken?.(BLOG_APP_ID),
    locale: wixCodeApi.site.language,
    trackError: console.error,
  };
  const request = createRequest({
    ...requestConfig,
    httpClient: getHttpClientFromFlowAPI(flowAPI),
  });

  const start = () => request<ProvisioningResponse>('/_api/blogs/this');
  const sleep = (timeout: number) =>
    new Promise((resolve) => setTimeout(resolve, timeout));
  const retry = (fn: typeof start, retries = 10, timeout = 300) =>
    new Promise<ProvisioningResponse>((resolve, reject) => {
      const doRetry = (availableRetries: number) =>
        sleep(timeout)
          .then(() => retry(fn, availableRetries - 1, timeout * 2))
          .then(resolve, reject);

      fn().then(
        (response) => {
          if (response === undefined) {
            resolve(undefined);
          } else if (response.status === 404 && retries > 1) {
            return doRetry(retries);
          } else if (response.status >= 500 && retries > 1) {
            return doRetry(retries);
          }
          resolve(response);
        },
        (error: unknown) => {
          if (retries > 1) {
            return doRetry(retries);
          }
          reject(error);
        },
      );
    });

  return start()
    .then(() => pageReady())
    .catch((response) => {
      if (response.status === 404) {
        fedopsLogger.interactionStarted(PROVISION_SUCCESS);
        setProps({ provisioningInProgress: true });
        retry(start)
          .then(() => {
            pageReady().then(() => {
              fedopsLogger.interactionEnded(PROVISION_SUCCESS);
              setProps({ provisioningInProgress: false });
            });
          })
          .catch(() => {
            setProps({
              provisioningError: true,
              provisioningInProgress: false,
              provisioningTimeout: true,
            });
          });
        return Promise.resolve();
      }
    });
}
