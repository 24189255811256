import {
  POST_LIST_SETTINGS_PARAMS,
  getWixDataCategoryId,
  getWixDataTagId,
  handleAggregatorResponseWithHeaders,
} from '@wix/communities-blog-client-common';
import setPosts from '../../common/actions/set-posts';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { getTotalResults } from '../../common/services/pagination';
import timezoneService from '../../common/services/timezone';
import { getTimezone } from '../../common/store/basic-params/basic-params-selectors';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';

const fetchRelatedPostsRenderModel = ({
  aggregatorRequest,
  state,
  page,
  fields,
}) => {
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({
    state,
    key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
  });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state);
  const timezone = getTimezone(state) || timezoneService.timezone();

  return aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      `timezone=${timezone}`,
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
      page && `page=${page}`,
      fields && `fields=${fields.join(',')}`,
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
};

const handlePostListPostsResponse =
  (postsResponse, { page = 1 } = {}) =>
  (dispatch, getState, { wixCodeApi, httpClient, aggregatorRequest }) => {
    dispatch(setIsLoading('postListPosts', undefined, true));

    return dispatch(handleAggregatorResponseWithHeaders(postsResponse))
      .then(async ({ body, headers }) => {
        if (!body.length && getEnvironment(wixCodeApi).isEditorSegment) {
          const state = getState();
          const fake = await getDemoPosts({
            httpClient,
            aggregatorRequest,
            getState,
            dispatch,
            wixCodeApi,
            query: {
              page,
              pageSize: getPostListWidgetPageSize(state),
              featuredOnly: getAppSettingsValue({
                state,
                key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
              }),
              categoryId: getWixDataCategoryId(state),
              tagId: getWixDataTagId(state),
              language: getQueryLocale(state),
            },
          });
          body = fake.posts;
          headers = fake.headers;
        }
        dispatch(setPosts(body));
        dispatch(setPostCount(getTotalResults(headers)));
      })
      .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
      .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
  };

export const fetchInitialData =
  () =>
  async (dispatch, getState, { aggregatorRequest, appParams }) => {
    const state = getState();
    const instanceId = getInstanceId(state);

    const { translations, posts, categories } =
      await fetchRelatedPostsRenderModel({
        aggregatorRequest,
        state,
        appParams,
      });

    await dispatch(handleTranslationsResponse(translations));
    if (categories) {
      await dispatch(handleCategoriesResponse(categories));
    }
    await dispatch(fetchTopology(instanceId));
    await dispatch(handlePostListPostsResponse(posts));
  };

export const fetchPostListPosts =
  (page) =>
  async (dispatch, getState, { aggregatorRequest, appParams }) => {
    const state = getState();

    const { posts } = await fetchRelatedPostsRenderModel({
      aggregatorRequest,
      state,
      appParams,
      page,
      fields: ['posts'],
    });

    await dispatch(handlePostListPostsResponse(posts, { page }));
  };

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
